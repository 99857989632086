<template>
    <div class="About">
        <div class="shop checkedbgc">
            <div class="img">
                <img :src="shop_info.logo_url" v-if="shop_info.logo_url" alt="">
                <img src="../assets/mendian.png" style="padding:1vh;" v-else alt="">
            </div>
            <div class="shopInfo">
                <div class="shopname">{{shop_info.shop_name}}</div>
                <div class="shopPhoto">{{shop_info.phone}}</div>
                <div class="shopPhoto">{{shop_info.address}}</div>
            </div>
        </div>
        <div class="menuList">
            <div class="shouyin"  @click="yingye">
                <span class="iconfont icon-shouyin1"></span>
                <div>营业收银</div>
            </div>
            <div class="huiyuan" @click="mis">
                <span class="iconfont icon-huiyuan"></span>
                <div>会员管理</div>
            </div>
            <div class="zhangdan" @click="bill">
                <span class="iconfont icon-zhangdanx"></span>
                <div>账单流水</div>
            </div>
            <div class="kucun" @click="kucun">
                <span class="iconfont icon-kucunpandian"></span>
                <div>库存管理</div>
            </div>
        </div>
        <div class="menuList">
            <div class="baobiao" @click="baobiao">
                <span class="iconfont icon-baobiao"></span>
                <div>统计报表</div>
            </div>
            <div class="tuihuo" @click="tuihuo">
                <span class="iconfont icon-a-171dingdanliebiao630-icon-zhuangtai-yituihuo"></span>
                <div>商品退货</div>
            </div>
            <div class="shezhi" @click="shezhi">
                <span class="iconfont icon-caozuo-shezhi"></span>
                <div>系统设置</div>
            </div>
            <div class="jiaoban" @click="jiaoban">
                <span class="iconfont icon-zuocedaohangtubiao-zhihuihuli-jiaobanjilu"></span>
                <div>交班退出</div>
            </div>
        </div>
        <div class="logo_box">
            <img :src="oemData.lower_right_img" class="img1" alt="">
            <!-- <img src="../assets/xinyishu.png" class="img2" alt=""> -->
        </div>
        <bottom></bottom>
    </div>
</template>
<script>
import bottom from '@/components/bottom/fastFoodBottom.vue'
import { mapState , mapActions } from "vuex";
export default {
    data(){
        return{
            
        }
    },
    components:{
        bottom
    },
    computed: {
        ...mapState(["shop_name","topTabList" , "shop_info" , "oemData","cashier" , "permission"]),
    },
    mounted(){
        
    },
    methods: {
        ...mapActions(['updateTabListAction','activeNameAction']),
        vuexHandle(data){
            this.activeNameAction(data.name)
            let arr = JSON.parse(JSON.stringify(this.topTabList))
            let i = arr.findIndex(item => item.name == data.name)
            if(i < 0){
                data.isDetele = true
                arr.push(data)
                this.updateTabListAction(arr)
            }
        },
        yingye(){
            if(this.cashier == 1){
                this.vuexHandle({
                    title:'收银',
                    path:'/yingye',
                    name: 'Yingye'
                })
                this.$router.push("/yingye");
            }else{
                this.vuexHandle({
                    title:'收银',
                    path:'/quickCashier',
                    name: 'quickCashier'
                })
                this.$router.push("/quickCashier");
            }
            
        },
        shezhi() {
            this.vuexHandle({
                title:'系统设置',
                path:'/install/index',
                name: 'install'
            })
            this.$router.push("/install/index");
        },
        mis() {
            this.vuexHandle({
                title:'会员管理',
                path:'/system',
                name: 'mis'
            })
            this.$router.push("/system");
        },
        bill() {
            this.vuexHandle({
                title:'账单',
                path:'/bill/flow',
                name: 'bill'
            })
            this.$router.push("/bill/flow");
        },
        kucun(){
            this.vuexHandle({
                title:'库存管理',
                path:'/goodsList',
                name: 'stock'
            })
            this.$router.push("/goodsList");
        },
        baobiao(){
            this.vuexHandle({
                title:'统计报表',
                path:'/statistics',
                name: 'baobiao'
            })
            this.$router.push("/statistics");
        },
        tuihuo(){
            if(this.permission.indexOf('/clerk/goods/goodsRefund') < 0){
                this.$message.warning('您还没有此权限')
                return
            }
            this.vuexHandle({
                title:'退货',
                path:'/returnGoods',
                name: 'tuihuo'
            })
            this.$router.push("/returnGoods");
        },
        jiaoban(){
            this.vuexHandle({
                title:'交班',
                path:'/jiaoban',
                name: 'jiaoban'
            })
            this.$router.push({
                path:"/jiaoban",
                query:{
                    jiaoban:'logout'
                }
            });
            
        }
    }
}
</script>
<style lang="less" scoped>
.About{
    .shop{
        // background: #ffebe8;
        width: 92vw;
        height: 18vh;
        margin: 5vh auto;
        display: flex;
        align-items: center;
        .img{
            width: 14vh;
            height: 14vh;
            // 
            border-radius: 50%;
            text-align: center;
            // padding: 1vh;
            margin: 2vh 5vw;
            img{
                background: #ffffff;
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
        .shopInfo{
            .shopname{
                font-size: 4vh;
                color: #505050;
                margin-bottom: 1vh;
            }
            .shopPhoto{
                font-size: 2vh;
                color: #a6a6a6;
            }
        }
    }
    .menuList{
        display: flex;
        width: 92vw;
        height: 17vh;
        margin: 5vh auto;
        >div{
            flex: 1;
            margin:0 2vh;
            border-radius: 1vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
               font-size: 8vh;
               line-height: 8vh;
               color: #ffffff;
               margin-bottom: 1vh;
           }
           >div{
               font-size: 3vh;
               color: #ffffff;
           }
        }
        .shouyin{
            background: #00adef;
            box-shadow: 0px 5px 5px 0px rgba(50, 147, 255, 0.27);
            
        } 
        .huiyuan{
            background: #ff6565;
            box-shadow: 0px 5px 5px 0px rgba(255, 103, 102, 0.27);
        }
        .zhangdan{
            background: #ffb76a;
            box-shadow: 0px 5px 5px 0px rgba(255, 183, 106, 0.27);
        }
        .kucun{
            background: #6599ff;
            box-shadow: 0px 5px 5px 0px rgba(42, 130, 228, 0.15);
        }
        .baobiao{
            background: #ffc300;
            box-shadow: 0px 5px 5px 0px rgba(255, 195, 0, 0.27);
            span{
                color: #ffc300;
                background: #ffffff;
                padding: 2vh;
                font-size: 4vh;
                line-height: 4vh;
                border-radius: 50%;
            }
        }
        .tuihuo{
            background: #ff8d1a;
            box-shadow: 0px 5px 5px 0px rgba(255, 141, 26, 0.27);
        }
        .shezhi{
            background: #2bd5dd;    
            box-shadow: 0px 5px 5px 0px rgba(43, 213, 221, 0.27);
            }
        .jiaoban{
            background: #ff679a;
            box-shadow: 0px 13px 13px 0px rgba(255, 103, 154, 0.27);
            span{
                color: #ff679a;
                background: #ffffff;
                padding: 2vh;
                font-size: 4vh;
                line-height: 4vh;
                border-radius: 50%;
            }
        }
    }
    .logo_box{
        text-align: right;
        opacity: 0.5;
        width: 92vw;
        height: 17vh;
        margin: 8vh auto;
        .img1{
            height: 8vh;
        }
        // .img2{
        //     height: 5vh;
        // }
    }
}
</style>