<template>
    <div class="bottoms">
        <div class="first">
            <div>{{oemData.company_name}}</div>
            <div>咨询电话：{{oemData.contact_mobile}}</div>
            <!-- <div>
                <span>网址：</span>
                <span @click="goto">https://www.qinghankeji.com</span>
            </div> -->
            
        </div>
        <div class="second" v-if="oemData.record">
            <a href="http://beian.miit.gov.cn/"  target="_blank">{{oemData.record}}</a>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['oemData']),
    },
    methods: { 
        goto(){
            // window.open('https://www.qinghankeji.com')
            // var text = 'https://www.qinghankeji.com'
            // var input = document.getElementById("input");
            // input.value = text;
            // input.select(); 
            // document.execCommand("Copy","false",null)
        }
    }
};
</script>
<style lang="less" scoped>
.bottoms {
    position: fixed;
    bottom:0;
    left: 4vw;
    width: 92vw;
    height: 11vh;
    border-top: 1px solid #e5e5e5;
    line-height: 3.5vh;
    padding: 2vh;
    color: #a6a6a6;
    font-size: 1.8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .first{
        display: flex;
        justify-content: center;
        align-items: center;
        
        >div {
            margin-right: 3vw;
            span:nth-of-type(2) {
                cursor: pointer;
            }
         }
    }
    .second{
        cursor: pointer;
        text-align: center;
        a{
          color: #a6a6a6; 
        }

    }
  }
 

</style>
